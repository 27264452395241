<script>
import detial from "./detail";
import {
  uploadDocument,
  getDocument,
  deleteDocument,
  changeDocumentStatus,
} from "@/api/document";
export default {
  components: { detial },
  data() {
    return {
      fileData: {},
      tableData: [],
      tableLoading: false,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      searchName: "",
      currentData: {},
      showDetail: false,
    };
  },
  methods: {
    handleChangeStatus(data) {
      changeDocumentStatus(data.id, {
        status: data.status,
      })
        .then((res) => {
          this.$message.success("修改成功");
        })
        .finally(() => {
          this.handlePageChange();
        });
    },
    handleDelete(data) {
      this.$confirm("确定要删除此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteDocument(data.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    handleDetial(data) {
      this.currentData = data;
      this.showDetail = true;
    },
    handlePageChange(page = this.pageNum) {
      this.pageNum = page;
      this.tableLoading = true;
      getDocument({
        page: this.pageNum,
        pageSize: this.pageSize,
        name: this.searchName,
      })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleClickUp() {
      this.$refs.fileInputPlan.click();
    },
    handlefileChange(data) {
      this.fileData = data.target.files[0];
      let formateData = new FormData();
      formateData.append("file", this.fileData);
      this.tableLoading = true;
      uploadDocument(formateData)
        .then((res) => {
          this.$message.success("上传成功");
          this.handlePageChange();
        })
        .finally(() => {
          this.tableLoading = false;
          this.$refs.fileInputPlan.value = "";
        });
    },
  },
  created() {
    this.handlePageChange();
  },
};
</script>
<template>
  <div
    class="
      user-tag
      part
      border-radius-6
      padding-20
      border-radius-6
      box-shadow-light-grey
      height-calc-type1
    "
  >
    <div class="operation-tab">
      <el-button @click="handlePageChange(pageNum)" style="margin-right: 20px"
        >刷新数据</el-button
      >
      <el-input
        v-model="searchName"
        clearable
        placeholder="请输入文件名"
        style="width: 200px; margin-right: 20px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handlePageChange(pageNum)"
        ></el-button>
      </el-input>
      <el-button
        @click="handleClickUp"
        class="input-update-button"
        slot="append"
        type="success"
        >上传文档</el-button
      >
    </div>
    <input
      style="position: absolute; z-index: -1; top: -9999px"
      ref="fileInputPlan"
      @change="handlefileChange"
      size="normal"
      type="file"
      accept=".xls,.xlsx"
    />
    <div class="custom-table">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="610px"
        v-loading="tableLoading"
      >
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="文件名" prop="excelName"></el-table-column>
        <el-table-column label="上传者" prop="adminUserName"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scoped">
            <el-switch
              v-model="scoped.row.status"
              :active-value="1"
              :inactive-value="2"
              @change="handleChangeStatus(scoped.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="上传日期" prop="createdTime"></el-table-column>
        <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="small"
              plain
              class="button-small"
              style="margin-right: 10px"
              @click="handleDetial(scope.row)"
            >
              详情
            </el-button>
            <el-button
              type="danger"
              size="small"
              class="button-small"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin: 20px 0; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 文档详情 -->
    <el-drawer
      :title="'详情：' + currentData.excelName"
      :visible.sync="showDetail"
      size="90%"
      :destroy-on-close="true"
      :show-close="true"
      append-to-body
      :before-close="() => (showDetail = false)"
    >
      <detial v-if="showDetail" :show="showDetail" :current="currentData" />
    </el-drawer>
  </div>
</template>



<style lang='scss' scoped>
.user-tag {
  background: #fff;
}
.custom-table {
  margin-top: 20px;
}
</style>
